import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  filterOutNewsletterPosts
} from '../lib/helpers'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain } from '../containers'
import Hero from '../components/hero'
import PortableText from '../components/portableText'
import { EmailOptinCore } from '../components/EmailOptin'
import { AdPanel, SidebarPanel, SidebarGrid } from './index'
import {PostPreview, PostsGrid} from './index'


export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query NewsletterPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(
      limit: 20
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          categories {
            title
          }
          authors{
            author{
              name
              id
            }
          }
          mainImage {
            asset{
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 400) {
                ...GatsbySanityImageFixed
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const Title = styled.h1`
  font-weight: 600;
`

const H2 = styled.h2`
  margin: 48px auto 12px auto;
`
const ArticleContainer = styled(ContainerMain)`
  /* max-width: 1000px; */
  /* padding: 24px 24px 24px 48px; */
`

const Article = styled.article`
  max-width: 900px;
`

const AboutPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const posts = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
      .filter(filterOutNewsletterPosts)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO
        title={'Newsletter'}
        description={site.description}
        keywords={site.keywords}
      />
      <ArticleContainer>
        <Article>
          <Title>WalletBurst Monthly Newsletter</Title>
          <p>
            Are you enjoying my content here at WalletBurst?
            My newsletter is simple: one email per month where I share my favorite
            personal finance and investing-related content from around the web as well as recap
            any new content that I have posted here. Some things that I share could include
            articles, books, book notes, tweetstorms, tools, infographics, podcasts, and YouTube videos.
          </p>

          <p>I'll also be archiving my newsletters here as I publish them.</p>

          <SidebarPanel>
            <EmailOptinCore />
          </SidebarPanel>

          <H2>Newsletter Archive</H2>

          <PostsGrid>
            {posts && (posts.map(post => (<PostPreview post={post} key={post.id} />)) )}
          </PostsGrid>

        </Article>
      </ArticleContainer>

    </Layout>
  )
}

export default AboutPage
